<template>
  <section v-if="!loading" class="billing scroll-content">
    <div class="billing__content">
      <h3 class="md-display-1">
        {{
          $translate(
            `user.billing.${
              !subscription_payed ? "title_before_payment" : "title"
            }`
          )
        }}

        <md-button
          v-if="subscription_payed"
          class="billing__back"
          @click="$router.push(`/admin/${$route.params?.store_id}`)"
        >
          {{ $translate("user.billing.back_button") }}
          <md-icon>navigate_next</md-icon>
        </md-button>
      </h3>

      <div v-if="!subscription_payed">
        <p
          class="md-subheading"
          v-html="$translate('user.billing.message_before_payment')"
        />
        <md-button
          @click="add_payment_method"
          class="billing__subscribe md-raised md-success"
        >
          {{ $translate("user.billing.submit_button") }}
        </md-button>
      </div>

      <div v-else>
        <div class="md-body-1">
          {{ $translate("user.billing.payment_method_message") }}
        </div>
        <p
          v-if="!subscription_payed && is_still_free_trial"
          class="md-body-2"
          v-html="$translate('user.billing.subscription_message')"
        />

        <div
          class="billing__message"
          :class="{
            'md-success': subscription_payed,
            'md-alert': !subscription_payed,
          }"
        >
          <md-icon>
            {{ subscription_payed ? "done" : "error_outline" }}
          </md-icon>
          {{
            $translate(
              `user.billing.statuses.${
                subscription_payed ? "subscribed" : "unsubscribed"
              }`
            )
          }}
        </div>
        <md-button @click="update_payment_method" class="md-raised md-primary">
          {{ $translate("user.billing.payment_method_button_change") }}
        </md-button>
      </div>
    </div>

    <img v-if="!subscription_payed" src="/static/backgrounds/billing-img.png" alt="image rocket" />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  ADMIN_STORE,
  CONFIGURATION_STORE,
  severities,
  USER_STORE,
} from "../../constants/others_constants";
import {
  ADD_GLOBAL_ERROR,
  FETCH_CONFIGURATION_DATA,
  FETCH_FIREBASE_DATA,
  UPDATE_LOADER,
} from "../../stores/Admin/constants";
import { SHARED_STORE } from "../../../Shared/constants/other";
import {
  create_billing_session,
  create_subscription_session,
} from "../../constants/endpoints/other";
import { user_admin_store_id } from "../../../../data/other_constants";
import { SET_USER_SELECTED_PROJECT } from "../../stores/Admin/user/constants";
import { ADMIN_LOGIN } from "../../../Shared/stores/Shared/constants";

export default {
  data() {
    return {
      delete_account: false,
      open_store_deletion_modal: false,
      store_name_input: "",
      date: new Date().toISOString().split("T")[0],
      elements: null,
      stripe: null,
      current_store_id: null,
    };
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, [
      "project_config",
    ]),
    ...mapState(USER_STORE, [
      "permissions",
      "paying_user",
    ]),
    ...mapState(SHARED_STORE, ["is_global_admin"]),
    ...mapState(ADMIN_STORE, [
      "free_trial_end",
      "subscription_payed",
      "loading",
    ]),
    is_still_free_trial() {
      return (
        Number(this.free_trial_end) >
        Number((new Date().getTime() / 1000).toFixed(0))
      );
    },
  },
  async mounted() {
    this.update_loader(true);
    this.current_store_id = this.$route.params?.store_id;

    if (
      this.current_store_id &&
      this.current_store_id !== this.project_config?.id
    ) {
      window.localStorage.setItem(user_admin_store_id, this.current_store_id);

      this.set_store(this.current_store_id);
      await this.admin_login({ project_id: this.current_store_id }, false);
      await this.fetch_initial_data(false);
      await this.fetch_configuration(false);
    }

    if (!this?.permissions?.admin) return this.$router.push("/admin");
    if (
      this.$route.query?.stripe_session_canceled ||
      this.$route.query?.stripe_session_updated ||
      this.$route.query?.stripe_session_created
    ) {
      if (this.paying_user)
        this.add_global_error({
          message: this.$translate(
            `user.billing.statuses.${
              this.$route.query.stripe_session_canceled
                ? "canceled"
                : this.$route.query.stripe_session_updated
                ? "updated"
                : "created"
            }`
          ),
          severity: severities.SUCCESS,
        });
      else
        this.add_global_error({
          message: this.$translate("user.billing.statuses.error"),
        });
    }

    this.update_loader(false);
  },
  methods: {
    ...mapActions(CONFIGURATION_STORE, {
      fetch_configuration: FETCH_CONFIGURATION_DATA,
    }),
    ...mapActions(ADMIN_STORE, {
      fetch_initial_data: FETCH_FIREBASE_DATA,
    }),
    ...mapActions(SHARED_STORE, {
      admin_login: ADMIN_LOGIN,
    }),
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
      update_loader: UPDATE_LOADER,
    }),
    ...mapMutations(USER_STORE, {
      set_store: SET_USER_SELECTED_PROJECT,
    }),
    async add_payment_method() {
      const user_coming_from_guide = this.$route.query?.guide;
      const guide_user_redirect_route = "/admin?new_store=true"
      const correct_sub_route =
        user_coming_from_guide === "true"
          ? guide_user_redirect_route
          : (this.subscription_payed
            ? this.$route.path
            : `/admin${
                this.is_global_admin ? `/${this.current_store_id}` : ""
              }`);
      const correct_redirect_url = window.location.origin + correct_sub_route
              
      this.update_loader();
      const { data: payment_url } = await create_subscription_session({
        redirect_url: correct_redirect_url,
        trial_end: this.free_trial_end,
      });
      window.location = payment_url;
    },
    async update_payment_method() {
      this.update_loader();
      const { data: payment_update_url } = await create_billing_session({
        redirect_url: window.location.origin + this.$route.path,
      });
      window.location = payment_update_url;
    },
  },
};
</script>

<style lang="scss">
@use "../../../../styles/_admin-constants" as *;
@use "../../../../styles/_global-constants" as *;

.billing {
  max-width: 800px;
  margin: 0 auto;

  &__content {
    position: relative;
    z-index: 1;

    .md-display-1 {
      color: $pure-black !important;
      font-weight: bold;
    }

    @media (max-width: $small-desktop) {
      padding: 0 $default-size;
    }
  }

  img {
    position: absolute;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    height: 100% !important;
    top: -#{$double_default_size};
    left: 0;
    opacity: 0.3;
  }

  &__submit {
    display: block !important;
    margin: $double_default_size auto 0 !important;
  }

  &__back {
    float: right;

    .md-button-content {
      display: flex;
      align-items: center;
      gap: $half-default-size;
    }
  }

  &__message {
    width: 100%;
    max-width: 300px;
    margin: $default-size 0;
    border-radius: $border-radius;
    font-size: 15px;
    text-align: center;
    font-weight: bold;

    i {
      display: inline-block;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      font-size: 32px !important;
      margin: $half-default-size $half-default-size $half-default-size 0;
      color: $pure-white !important;
    }
  }

  &__subscribe {
    display: block !important;
    height: auto !important;
    margin: $double-default-size auto 0 !important;
    padding: $default-size $double-default-size !important;
  }
}
</style>
